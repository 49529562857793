import React, { Component } from "react"
import styles from "./text-input.module.scss"
import anime from "animejs/lib/anime.es.js"

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      labelTransition: null,
      active: false,
    }
  }

  focus = event => {
    this.refs.input.focus()
    if (typeof document !== `undefined`) {
      if (
        document.activeElement === this.refs.input &&
        event.type === "focus"
      ) {
        if (this.props.input.value === "") {
          this.setState({
            active: true,
          })
        }
      }
    }
  }

  blur = () => {
    if (!this.props.input.value) {
      this.setState({
        active: false,
      })
    }
  }

  render() {
    const { active } = this.state
    const { color } = this.props
    console.log("color", color)
    return (
      <div
        className={[
          styles.field,
          active ? styles.active : null,
          color ? styles[`${color}`] : null,
        ].join(" ")}
        ref="component"
        onClick={this.focus}
      >
        <label className={styles.label}>
          <span className={styles.placeholder}>{this.props.placeholder}</span>
        </label>
        <input
          ref="input"
          className={styles.input}
          {...this.props.input}
          onFocus={this.focus}
          onBlur={this.blur}
          // onFocusOut={this.blur}
        />
        <span className={styles.line}></span>
      </div>
    )
  }
}
export default TextInput
