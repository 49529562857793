import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import { Swiper } from "swiper/dist/js/swiper.esm.js"

import QuoteItem from "components/QuoteItem"
import styles from "./quotes.module.scss"
import quoteItemStyles from "components/QuoteItem/quote-item.module.scss"

class Quotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      swiper: null,
      width: null,
    }
  }

  calculateSize = () => {
    if (typeof window !== `undefined`) {
      if (this.state.width < 1208 && this.state.swiper) {
        this.state.swiper.slideTo(0)
        this.state.swiper.allowTouchMove = true
      } else {
        if (this.state.swiper) {
          this.state.swiper.allowTouchMove = false
        }
      }
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  swiper = () => {
    let swiper = new Swiper(`.${styles.slides}`, {
      slidesPerView: "auto",
      direction: "horizontal",
      slideClass: quoteItemStyles.quote,
      wrapperClass: styles.wrap,
      allowTouchMove: true,
      speed: 200,
    })
    this.setState({ swiper: swiper })
  }

  componentDidMount() {
    this.calculateSize()
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.calculateSize)
    }
    this.swiper()
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.component.querySelector(`.${styles.container}`),
        duration: 400,
        translateY: ["104px", "0px"],
        opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelectorAll(`.${styles.line}`),
        duration: 600,
        width: ["0%", "100%"],
        // opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add(
        {
          targets: this.refs.component.querySelectorAll(
            `.${styles.container} > div`
          ),
          duration: 400,
          translateY: ["24px", "0px"],
          opacity: [0, 1],
          delay: anime.stagger(200),
          // easing: "spring(1, 100, 10, 0)",
        },
        "-=400"
      )

    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
      // if (this.state.transition && !this.state.transition.reversed) {
      //   this.state.transition.play()
      // }
    }
  }
  render() {
    const { items } = this.props.details
    const { type } = this.props

    return (
      <div className={styles.quoteContainer} ref="component">
        <div className={[styles.slides, "wrapper"].join(" ")}>
          <span className={styles.line} />
          <div className={[styles.wrap].join(" ")}>
            {items &&
              items.map((item, i) => (
                <QuoteItem
                  details={item}
                  type={type}
                  key={i}
                  viewport={
                    this.props.inViewport && this.props.enterCount === 1
                  }
                  delay={i * 100}
                />
              ))}
          </div>
          <span className={[styles.line, styles.lineBottom].join(" ")} />
        </div>
      </div>
    )
  }
}
const QuotesTransition = handleViewport(Quotes, {
  threshold: 0.3,
})
export default QuotesTransition
