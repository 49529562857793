import React, { Component } from "react"

import styles from "./loading.module.scss"

class Loading extends Component {
  render() {
    const { color } = this.props
    const isIE = /* @cc_on!@ */ false || !!document.documentMode
    return (
      <span className={["loading", styles.loading].join(" ")}>
        {isIE ? (
          <div className={styles.ie} />
        ) : (
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
      </span>
    )
  }
}

export default Loading
