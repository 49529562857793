import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

import styles from "./quote-item.module.scss"

class QuoteItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 2000,
      delay: this.props.delay,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.component.querySelectorAll(
          `.${styles.title} > span span`
        ),
        translateY: ["104px", "0px"],
        delay: anime.stagger(100),
        duration: 1400,
        easing: "easeInOutExpo",
      })
      .add(
        {
          targets: this.refs.component.querySelector(`.${styles.author}`),
          duration: 400,
          translateY: ["-24px", "0px"],
          opacity: [0, 1],
          easing: "easeInOutCirc(1, .5)",
        },
        `-=600`
      )

    this.setState({
      transition,
    })
    // this.state.transition.play()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.viewport) {
      this.state.transition.play()
    } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
      // if (this.state.transition && !this.state.transition.reversed) {
      //   this.state.transition.play()
      // }
    }
  }
  render() {
    const { quote, author, company } = this.props.details
    const { type } = this.props

    return (
      <div
        className={[styles.quote, type ? styles[`${type}`] : null].join(" ")}
        ref="component"
      >
        <h3 className={styles.title}>
          {quote.map((item, i) => (
            <span className={styles.row} key={i}>
              <span>
                {i === 0 && "“"}
                {item}
                {quote.length === i + 1 && "”"}
              </span>
            </span>
          ))}
        </h3>
        <span className={styles.author}>
          <span>{author}</span>
          <i className={styles.divider}>|</i>
          <span>{company}</span>
        </span>
      </div>
    )
  }
}

export default QuoteItem
